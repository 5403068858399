<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap>
            <v-flex xs12 v-if="isAdmin">
                <v-autocomplete v-model="item.idUser" :rules="[rules.required]" :items="userList" item-value="id" item-text="email" label="Usuários" deletable-chips
                    small-chips /></v-flex>
            <v-flex xs12 v-else>
                <v-text-field :value="item.user.name" label="Nome do usuário" outlined disabled />
                <v-text-field :value="item.user.email" label="Email do usuário" outlined disabled />
            </v-flex>

            <v-flex xs12 v-if="isAdmin">
                <v-autocomplete v-model="item.idCondominium" :rules="[rules.required]" :items="condominiumList" item-value="id" item-text="link" label="Condomínio" deletable-chips
                    small-chips /></v-flex>
            <v-flex xs12 v-else>
                <v-text-field :value="item.condominium.name" label="Condomínio" outlined disabled />
            </v-flex>

            <v-flex xs12>
                <v-text-field label="Bloco" v-model="item.block" :rules="[rules.required]" />
            </v-flex>
            <v-flex xs12>
                <v-text-field label="Apartamento" v-model="item.apartment" :rules="[rules.required]" />
            </v-flex>

            <v-flex xs12>
                <v-autocomplete v-model="item.status" :items="status" :rules="[rules.required]" item-value="key" item-text="description" label="Status" class="my-0 py-0"
                    deletable-chips small-chips />
            </v-flex>
            <v-flex xs12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate" style="width: 100%;">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import { mapGetters } from "vuex";

export default {
    directives: { mask },

    mounted() {
        this.isAdmin = this.verifyRole("ROLE_ADMIN");
        if (this.isAdmin) {
            this.get();
        }
    },

    computed: {
        ...mapGetters(["verifyRole"]),
    },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isAdmin: false,
            rules,
            valid: true,
            userList: [],
            condominiumList: [],
            itemClean: {
                idUser: "",
                status: "",
                block: "",
                apartment: "",
            },
            item: { ...this.itemClean },
            status: [
                { key: "OK", description: "Liberado" },
                { key: "PENDING", description: "Pendente" },
                { key: "BLOCKED", description: "Bloqueado" },
                { key: "DELETED", description: "Deletado" },
            ],
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                } else {
                    this.item = { ...newVal };
                }
            },
        },
    },

    methods: {
        get() {
            this.$http
                .get("api/v2/user")
                .then((result) => {
                    this.userList = result;
                })
                .catch(() => {
                    this.userList = [];
                });
            this.$http
                .get("api/v2/condominium")
                .then((result) => {
                    this.condominiumList = result;
                })
                .catch(() => {
                    this.condominiumList = [];
                });
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },
};
</script>